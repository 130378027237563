import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import H1 from "../components/H1"
import Hero from "../components/Hero"
import Container from "../components/Container"

import HeroButton from "../components/Hero/HeroButton"
import H2 from "../components/H2"
import Section from "../components/Section"

import CardImage from "../components/Card/CardImage"
import Card from "../components/Card"
import P from "../components/P"
import CardLink from "../components/Card/CardLink"
import theme from "../themes"
import panda from "../images/panda.svg"
import dog_funny from "../images/dog_funny_2.svg"
import turtle_chill from "../images/turtle_chill.svg"
import { Grid } from "@material-ui/core"
import BackgroundImage from "gatsby-background-image"

const IndexPage = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <SEO title="Startseite" />

      <BackgroundImage
        Tag={`section`}
        style={{
          minHeight: "600px",
          maxWidth: "1024px",
          margin: "0 auto 128px auto",
          backgroundPosition: "50% 30%",
        }}
        fluid={data.image1.childImageSharp.fluid}
      >
        <div
          style={{
            background: theme.main.colors.pink,
            padding: "16px",
            position: "absolute",
            top: "40px",
            left: "0",
          }}
        >
          <H1>Meine Kita</H1>
          <P>Hier fühle ich mich wie zu Hause!</P>
        </div>

        <HeroButton stripHash to="/buchen">
          Platz Buchen
        </HeroButton>
      </BackgroundImage>

      <Section>
        <Container>
          <H2>Was wir bieten</H2>
          <p>Unsere Dienstleistungen</p>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4} style={{ marginBottom: "40px" }}>
              <Card bgColor={theme.main.colors.greenLight}>
                <CardImage src={turtle_chill} />
                <H2>Unser Leitbild</H2>
                <p>
                  Uns ist es wichtig, die Kinder in ihrer Ganzheit zu
                  akzeptieren.
                </p>
                <CardLink to="/paedagogik">Mehr erfahren</CardLink>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} style={{ marginBottom: "40px" }}>
              <Card bgColor={theme.main.colors.pinkDark}>
                <CardImage src={panda} />
                <H2>Betreeungsangebote</H2>
                <p>Bei uns stehen Sie nicht im Regen.</p>
                <CardLink to="/betreuungsangebote">Mehr erfahren</CardLink>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} style={{ marginBottom: "40px" }}>
              <Card bgColor={theme.main.colors.yellow}>
                <CardImage src={dog_funny} />

                <H2>Kostenlose Schnupperstunde</H2>
                <P>Unverbindlich ausprobieren.</P>
                <CardLink to="/buchen">Mehr erfahren</CardLink>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "kid_smiling.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(quality: 90, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
