import styled, { css } from "styled-components"

const CardImage = styled.img`
  height: 120px;
  display: block;
  margin: 0 auto;
  border-radius: 8px;

  ${props =>
    props.position &&
    css`
      position: ${props.position.position ? props.position.position : "static"};
      top: ${props.position.top ? props.position.top : "auto"};
      right: ${props.position.right ? props.position.right : "auto"};
      bottom: ${props.position.bottom ? props.position.bottom : "auto"};
    `}
`
export default CardImage
